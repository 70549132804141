import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyAHQcr3anIneSptJtUyPn66q6NIqRo6-Pg",
  authDomain: "hullan.firebaseapp.com",
  databaseURL: "https://hullan-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "hullan",
  storageBucket: "hullan.appspot.com",
  messagingSenderId: "960477998288",
  appId: "1:960477998288:web:4ea5d92fad81ac1ac4c2d5",
  measurementId: "G-VP0SLNGD7Z",
};

firebase.initializeApp(firebaseConfig);

export const db = firebase;

var firepadRef = firebase.database().ref();

export let userName = "";
export let roomId = "";

export const setUsername = async (username) => {
  userName = username;
};

export const setRoomID = async (id) => {
  firepadRef = firepadRef.child(id);
};

export default firepadRef;
