import MainScreen from "./components/MainScreen/MainScreen";
import firepadRef, {
  db,
  setRoomID,
} from "./config/firebase";
import "./App.css";
import { useEffect, useState } from "react";
import {
  setMainStream,
  addParticipant,
  setUser,
  removeParticipant,
  updateParticipant,
} from "./store/actioncreator";
import { connect } from "react-redux";
import axios from "axios";

const App = (props) => {
  const urlparams = new URLSearchParams(window.location.search);
  const slug = urlparams.get("id");
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const getUserStream = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    return localStream;
  };

  useEffect(() => {
    setLoading(true);
    if (slug !== undefined) {
      axios.get(`https://api.fittcloud.net/v1/meetings/${slug}`).then((res) => {
        setData(res.data);
      });

      setLoading(() => setLoading(false), 2000);
    }
  }, [slug]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (Object.keys(data).length > 0) {
      console.log(data);
      await setRoomID(data.room_code);
      const stream = await getUserStream();
      stream.getVideoTracks()[0].enabled = false;
      props.setMainStream(stream);

      connectedRef.on("value", (snap) => {
        if (snap.val()) {
          const defaultPreference = {
            audio: true,
            video: false,
            screen: false,
          };
          const userStatusRef = participantRef.push({
            userName: data?.user_name,
            preferences: defaultPreference,
          });
          props.setUser({
            [userStatusRef.key]: { name: data?.user_name, ...defaultPreference },
          });
          userStatusRef.onDisconnect().remove();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const connectedRef = db.database().ref(".info/connected");
  const participantRef = firepadRef.child("participants");

  const isUserSet = !!props.user;
  const isStreamSet = !!props.stream;

  useEffect(() => {
    if (isStreamSet && isUserSet) {
      participantRef.on("child_added", (snap) => {
        const preferenceUpdateEvent = participantRef
          .child(snap.key)
          .child("preferences");
        preferenceUpdateEvent.on("child_changed", (preferenceSnap) => {
          props.updateParticipant({
            [snap.key]: {
              [preferenceSnap.key]: preferenceSnap.val(),
            },
          });
        });
        const { userName: name, preferences = {} } = snap.val();
        props.addParticipant({
          [snap.key]: {
            name,
            ...preferences,
          },
        });
      });
      participantRef.on("child_removed", (snap) => {
        props.removeParticipant(snap.key);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStreamSet, isUserSet]);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="App">
      <MainScreen data={data} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stream: state.mainStream,
    user: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    addParticipant: (user) => dispatch(addParticipant(user)),
    setUser: (user) => dispatch(setUser(user)),
    removeParticipant: (userId) => dispatch(removeParticipant(userId)),
    updateParticipant: (user) => dispatch(updateParticipant(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
